<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"

    >
        <template #modal-title>
            {{action == 'editing'?$t('label_category_edit'):$t('label_category_add')}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_category_name')"
                        rules="required"
                        slim
                        mode="aggressive"
                >
                    <b-form-group
                            :label="$t('label_category_name')"

                    >
                        <b-form-input

                                v-model="itemData.name"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_category_name')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <!--<validation-provider-->
                        <!--class="mt-2"-->
                        <!--#default="validationProps"-->
                        <!--:name="$t('label_active')"-->
                        <!--slim-->
                <!--&gt;-->
                    <!--<b-form-group-->
                            <!--label=""-->
                    <!--&gt;-->
                        <!--&lt;!&ndash;:checked="itemData.google_sync"&ndash;&gt;-->
                        <!--<b-form-checkbox-->
                                <!--id-="cat-status"-->
                                <!--value="1"-->
                                <!--unchecked-value="0"-->
                                <!--v-model="itemData.status"-->
                        <!--&gt;-->
                            <!--<label for="cat-status">{{ $t('label_active') }}</label>-->
                        <!--</b-form-checkbox>-->
                    <!--</b-form-group>-->
                <!--</validation-provider>-->


                <div class="d-flex justify-content-end mt-2">
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">
                        {{$t('label_close')}}
                    </button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-primary waves-effect waves-light">{{(action ==
                        'editing')?$t('label_save'):$t('label_add')}}
                    </button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BInputGroupPrepend,
    } from 'bootstrap-vue'

    import {MODULE_PREFIX, CATEGORY_PREFIX as PREFIX} from '../moduleHelper'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,

        },
        props: ['editedItem'],
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                action: 'adding',

                blankItemData: {
                    name: '',
                    // status: 1,
                },

                itemData: {}

            }
        },

        methods: {
            initData() {

                if (this.editedItem) {
                    this.action = 'editing';
                    let item = Object.assign({}, this.blankItemData);
                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }
                    this.itemData = item;
                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);

                }
            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {

                if (this.action == 'adding') {
                    this.async('post', '/' + this.MODULE_PREFIX + '_categories' , this.itemData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                        // this.$router.push({ name: 'contract_categories'});
                    });

                } else {
                    this.async('put', '/' + this.MODULE_PREFIX + '_categories/' + this.itemData.id, this.itemData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                        // this.$router.push({ name: 'contract_categories'});
                    });

                }

            }
        },

    }
</script>